<template>
  <div class="flex h-full grow flex-col">
    <ClientOnly>
      <NuxtLoadingIndicator />
    </ClientOnly>

    <G7Header />

    <div class="relative top-[5.625rem] flex grow flex-col lg:static">
      <main class="min-h-60 grow">
        <Navbar class="hidden lg:flex" />
        <slot />
      </main>

      <G7Footer />
    </div>
  </div>
</template>

<script setup lang="ts"></script>
